import React from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

function CheckToken() {

  const history = useHistory()

  try {

    console.log(localStorage.getItem("exhibitionToken"))
  
    if(localStorage.getItem("exhibitionToken") == null) throw "No token"
  
    axios.post('https://api.exhibition.tecnodatasystem.eu/checkToken.php', {
      token: localStorage.getItem("exhibitionToken")
    })
    .then(function (response) {
     var response = response.data
     console.log(response)
     if(response.status != 'ok') {
       localStorage.removeItem("exhibitionToken")
       history.push("../login")
     }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  catch(e) {
    history.push("../login")
  }

}

export default CheckToken