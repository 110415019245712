import React, { useEffect, useState, useCallback, useReducer } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import MUIDataTable from "mui-datatables"

import { Button } from "@material-ui/core"

// components
import PageTitle from "../../components/PageTitle"
import Widget from "../../components/Widget"
import Table from "../dashboard/components/Table/Table"

import VisibilityIcon from "@material-ui/icons/Visibility"
import SendIcon from "@material-ui/icons/Send"
import TouchAppIcon from "@material-ui/icons/TouchApp"

import { useTheme } from "@material-ui/styles"
import { useLocation, useParams } from "react-router-dom"

import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import Box from "@material-ui/core/Box"

import axios from "axios"

import "react-dropzone-uploader/dist/styles.css"
import Dropzone from "react-dropzone-uploader"

import Modal from "@material-ui/core/Modal"

import { useHistory } from "react-router-dom"

// data
import mock from "../dashboard/mock"
import { idea } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { FormatListBulleted } from "@material-ui/icons"

import { Check, X, ExternalLink, ThumbsUp, ThumbsDown, Trash } from "react-feather"

import { ClipLoader } from "react-spinners"

const useStyles = makeStyles((theme) => ({
	tableOverflow: {
		overflow: "auto"
	}
}))

export default function Upload() {
	const [_, forceUpdate] = useReducer((x) => x + 1, 0)

	const classes = useStyles()

	var theme = useTheme()

	const [isSmallScreen, setIsSmallScreen] = useState(false)

	const [quotationId, setQuotationId] = useState(null)

  const location = useLocation()
  
  const { viewId, customerName } = useParams()

	const history = useHistory()

	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)

	const [photos, setPhotos] = useState([])

	const [isModalOpen, setIsModalOpen] = useState(false)

	const [currentPicData, setCurrentPicData] = useState(null)

	//const [customerName, setCustomerName] = useState("...")

	const [currentQuotationCode, setCurrentQuotationCode] = useState("")

	function updatePhotoDescription(id, description) {
		axios
			.post("https://api.exhibition.tecnodatasystem.eu/updatePhotoDescription.php", {
				token: localStorage.getItem("exhibitionToken"),
				id: id,
				description: description
			})
			.then(function (response) {
				var response = response.data
				if (response.status == "ok") {
					var x = photos
					x.find((x) => x.id == id).updating = false
					setPhotos(x)
					forceUpdate()
				}
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	function retrievePhotos(id) {
		axios
			.post("https://api.exhibition.tecnodatasystem.eu/retrievePhotosForQuotation.php", {
				token: localStorage.getItem("exhibitionToken"),
				id: id
			})
			.then(function (response) {
				var response = response.data
				console.log(response)

				if (response.status == "ok") {
					setCurrentQuotationCode(response.quotationCode)
					var x = []
					response.data.forEach(function (resp) {
						//var data = JSON.parse(resp.data)
						x.push({
							id: resp.id,
							fullname: resp.fullname,
							description: resp.description,
							upload_datetime:
								resp.upload_datetime == null
									? "Non disponibile"
									: resp.upload_datetime.split(" ")[0].split("-").reverse().join("/") +
									  " " +
									  resp.upload_datetime
											.split(" ")[1]
											.substring(0, resp.upload_datetime.split(" ")[1].length - 3)
						})
					})
					setPhotos(x)
					forceUpdate()
				}
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	useEffect(() => {
		console.log(photos)
	}, [photos])

	function deleteQuotationPhoto(id) {
		var x = window.confirm("Sei sicuro di voler eliminare la foto?")
		if (!x) return

		axios
			.post("https://api.exhibition.tecnodatasystem.eu/deleteQuotationPhoto.php", {
				token: localStorage.getItem("exhibitionToken"),
				id: id
			})
			.then(function (response) {
				var response = response.data
				console.log(response)

				if (response.status == "ok") {
					retrievePhotos(quotationId)
				}
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	const columns = [
		{
			name: "upload_datetime",
			label: "Data caricamento",
			options: {
				filter: true,
				sort: false
			}
		},
		{
			name: "fullname",
			label: "Vista",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					var fullname = photos[tableMeta.rowIndex].fullname
					return (
						<div>
							<img
								src={fullname}
								width={150}
								height={80}
								style={{ borderRadius: 20, cursor: "pointer" }}
								onClick={() => {
									setIsModalOpen(true)
									setCurrentPicData(photos[tableMeta.rowIndex].fullname)
								}}
							/>
						</div>
					)
				}
			}
		},
		{
			name: "description",
			label: "Descrizione",
			options: {
				tiler: true,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div>
							<div style={{ cursor: "pointer", display: "none" }}>
								{JSON.stringify(photos[tableMeta.rowIndex])}
							</div>
							<div>
								<textarea
                placeholder="Aggiungi una descrizione"
									rows={4}
									style={{
										borderRadius: 5,
										minHeight: 30,
										borderWidth: 0.1,
										borderColor: "lightgrey"
									}}
									value={photos[tableMeta.rowIndex].description}
									onChange={(v) => {
										var x = photos
										x[tableMeta.rowIndex].description = v.target.value
										setPhotos(x)
										forceUpdate()
									}}
									onBlur={() => {
										var x = photos
										x[tableMeta.rowIndex].updating = true
										setPhotos(x)
										forceUpdate()
										setTimeout(() => {
											updatePhotoDescription(
												photos[tableMeta.rowIndex].id,
												photos[tableMeta.rowIndex].description
											)
										}, 100)
									}}
								></textarea>
								{
									<ClipLoader
										size={20}
										css={{
											paddingTop: 10,
											marginBottom: -5,
											marginLeft: 10,

											visibility:
												photos[tableMeta.rowIndex].updating !== undefined &&
												photos[tableMeta.rowIndex].updating
													? "visible"
													: "hidden"
										}}
									/>
								}
							</div>
						</div>
					)
				}
			}
		},
		{
			name: "actions",
			label: "Azioni",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div>
							<Button
								color="primary"
								variant="contained"
								title="Vedi"
								//style={{ backgroundColor: "green" }}
								onClick={() => {
									var photo = photos[tableMeta.rowIndex].id
									var id = quotationId
									window.open(
										"https://api.exhibition.tecnodatasystem.eu/360?id=" + id + "&photo=" + photo,
										"_blank"
									)
								}}
							>
								<VisibilityIcon />
							</Button>

							<Button
								color="secondary"
								variant="contained"
								title="Elimina"
								style={{
									backgroundColor: "darkred",
									marginLeft: !isSmallScreen ? 5 : null,
									marginRight: !isSmallScreen ? 5 : null,
									marginBottom: isSmallScreen ? 5 : null,
									marginTop: isSmallScreen ? 5 : null
								}}
								onClick={() => {
									deleteQuotationPhoto(photos[tableMeta.rowIndex].id)
								}}
							>
								<Trash />
							</Button>
						</div>
					)
				}
			}
		}
	]

	//console.log(history)

	// specify upload params and url for your files

	const getUploadParams = ({ meta }) => {
		return { url: "https://httpbin.org/post" }
	}

	// called every time a file's `status` changes
	const handleChangeStatus = ({ meta, file }, status) => {
		console.log(status, meta, file)
	}

	// receives array of files that are done uploading when submit button is clicked
	const handleSubmit = (files, allFiles) => {
		console.log(files.map((f) => f.meta))
		//allFiles.forEach((f) => f.remove())

		// console.log(files)

		var data = new FormData()

		allFiles.forEach(function (resp, i = 0) {
			console.log(resp)
			i++
			data.append("file_" + i, resp.file)
		})

		if (quotationId !== null) {
			data.append("quotationId", quotationId)
			data.append("token", localStorage.getItem("exhibitionToken"))
		} else {
			data.append("quotationId", 0)
		}

		console.log(data.values())

		setSubmitButtonDisabled(true)

		axios({
			method: "post",
			url: "https://api.exhibition.tecnodatasystem.eu/uploadQuotationFiles.php",
			data: data,
			headers: { "Content-Type": "multipart/form-data" }
		})
			.then(function (response) {
				//handle success
				var response = response.data
				console.log(response)
				if (response.status == "ok") {
					allFiles.forEach((f) => f.remove())
					retrievePhotos(quotationId)
					setTimeout(() => {
						setSubmitButtonDisabled(false)
					}, 3000)
				} else {
					alert("Errore nel caricamento.")
					setSubmitButtonDisabled(false)
				}
			})
			.catch(function (response) {
				//handle error
				console.log(response)
			})
	}

	useEffect(() => {
		var windowWidth = window.innerWidth
		var breakpointWidth = theme.breakpoints.values.md
		setIsSmallScreen(windowWidth < breakpointWidth)

		try {
			setQuotationId(viewId)
			setTimeout(() => {
				//setCustomerName(history.location.state.customerName)
			}, 450)
			console.log("done!")
			retrievePhotos(viewId)
		} catch (e) {}
	}, [])

	return (
		<>
			<PageTitle title={"Progetto 360° - " + customerName} />
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<div style={{ textAlign: "right", marginBottom: 20 }}>
						<Button
							title="Vedi"
							style={{ backgroundColor: "transparent", color: "black", borderWidth: 0 }}
							onClick={() => {
								//	var photo = photos[tableMeta.rowIndex].id
								//		var id = quotationId
								window.open(
									"https://api.exhibition.tecnodatasystem.eu/360?code=" + currentQuotationCode,
									"_blank"
								)
							}}
						>
							<ExternalLink />
							&nbsp;&nbsp;Apri in simulazione
						</Button>
					</div>
					<Paper square>
						{quotationId == null && (
							<div style={{ textAlign: "center", padding: 50 }}>
								Non stai visualizzando alcun progetto.
								<br />{" "}
								<span
									style={{ cursor: "pointer" }}
									onClick={() => {
										history.push({
											pathname: "tables"
										})
									}}
								>
									Torna alla pagina dei preventivi.
								</span>
							</div>
						)}
						{photos.length < 1 && quotationId > 0 && (
							<div style={{ textAlign: "center", padding: 50 }}>
								<ClipLoader />
								<br />
								<br />
								<span style={{ marginTop: 10 }}>
									Le foto caricate appariranno automaticamente qui.
								</span>
							</div>
						)}
						{photos.length > 0 && quotationId > 0 && (
							<MUIDataTable
								data={photos}
								columns={columns}
								options={{
									filterType: "dropdown",
									selectableRows: false,
									textLabels: {
										body: {
											noMatch: "Nessun risultato trovato",
											toolTip: "Ordina",
											columnHeaderTooltip: (column) => `Ordina per ${column.label}`
										},
										pagination: {
											next: "Prossima pagina",
											previous: "Pagina precedente",
											rowsPerPage: "Righe per pagina:",
											displayRows: "di"
										},
										toolbar: {
											search: "Cerca",
											downloadCsv: "Scarica CSV",
											print: "Stampa",
											viewColumns: "Vedi Colonne",
											filterTable: "Filtra Tabella"
										},
										filter: {
											all: "Tutti",
											title: "FILTRI",
											reset: "REIMPOSTA"
										},
										viewColumns: {
											title: "Mostra Colonne",
											titleAria: "Mostra/Nascondi Colonne"
										},
										selectedRows: {
											text: "righe selezionate",
											delete: "Elimina",
											deleteAria: "Elimina righe selezionate"
										}
									}
								}}
							/>
						)}
					</Paper>
				</Grid>
				<Dropzone
					getUploadParams={getUploadParams}
					onChangeStatus={handleChangeStatus}
					onSubmit={handleSubmit}
					accept="image/*,audio/*,video/*"
					inputContent={"Scegli File o Trascina"}
					inputWithFilesContent={"Aggiungi file"}
					submitButtonContent={"Carica"}
					submitButtonDisabled={quotationId > 0 && !submitButtonDisabled ? false : true}
				/>
				{/* <Grid item xs={12}>
          <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <Table data={mock.table} />
          </Widget>
        </Grid> */}
				<Modal
					open={isModalOpen}
					onClose={() => {
						//console.log("asd")
					}}
					aria-labelledby="Visualizza foto"
					aria-describedby="Visualizza foto"
				>
					<div
						style={{
							textAlign: "center",
							marginTop: "5%"
						}}
					>
						<div
							style={{ marginBottom: 10, cursor: "pointer" }}
							onClick={() => {
								setIsModalOpen(false)
								setCurrentPicData(false)
							}}
						>
							<X color="white" />
						</div>
						{currentPicData != null && <img src={currentPicData} width={"80%"} height={"80%"} />}
					</div>
				</Modal>
			</Grid>
		</>
	)
}
