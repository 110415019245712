import React, { useState, useEffect, useReducer } from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import MUIDataTable from "mui-datatables"

import { Button } from "@material-ui/core"

import { useHistory } from "react-router-dom"

// components
import PageTitle from "../../components/PageTitle"
import Widget from "../../components/Widget"
import Table from "../dashboard/components/Table/Table"

import VisibilityIcon from "@material-ui/icons/Visibility"
import SendIcon from "@material-ui/icons/Send"
import TouchAppIcon from "@material-ui/icons/TouchApp"
import EuroIcon from "@material-ui/icons/Euro"

import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import Modal from "@material-ui/core/Modal"

import { X } from "react-feather"

import Box from "@material-ui/core/Box"


// data
import mock from "../dashboard/mock"
import { idea } from "react-syntax-highlighter/dist/esm/styles/hljs"

import { useTheme } from "@material-ui/styles"

import { UploadCloud, Globe } from "react-feather"

import axios from "axios"

const useStyles = makeStyles((theme) => ({
	tableOverflow: {
		overflow: "auto"
	}
}))

export default function Tables() {
	const [, forceUpdate] = useReducer((x) => x + 1, 0)

	const [quotations, setQuotations] = useState([])

	const [currentViewAmount, setCurrentViewAmount] = useState("0")
	const [currentQuotationIdAmountToEdit, setCurrentQuotationIdAmountToEdit] = useState(0)

	const [isModalOpen, setIsModalOpen] = useState(false)

	const classes = useStyles()
	const history = useHistory()

	const MySwal = withReactContent(Swal)

	function sendQuote(email, code) {
		window.location.href = "mailto:" + email + "?subject=" + code
	}

	function viewQuote(id, customerName) {
		history.push({
			pathname: "view/" + id + "/" + customerName,
			state: {
				id: id
			}
		})
	}

	function actuallySetAmountFunction(id, newAmount) {

		axios
		.post("https://api.exhibition.tecnodatasystem.eu/setAmountFunction.php", {
			token: localStorage.getItem("exhibitionToken"),
			newAmount: newAmount,
			id: id
		})
		.then(function (response) {
			var response = response.data
			if (response.status == "ok") {
				setIsModalOpen(false)
				setTimeout(() => {
					retrieveQuotations()
				}, 250);
			}
		})
		.catch(function (error) {
			console.log(error)
		})

	}



	function changePublishState(id, isPublished) {
		var x = window.confirm(
			isPublished
				? "Sei sicuro di voler nascondere il progetto dal sito?"
				: "Sei sicuro di voler pubblicare il progetto sul sito?"
		)
		if (!x) return
		axios
			.post("https://api.exhibition.tecnodatasystem.eu/changePublishState.php", {
				token: localStorage.getItem("exhibitionToken"),
				newStatus: isPublished ? 0 : 1,
				id: id
			})
			.then(function (response) {
				var response = response.data
				if (response.status == "ok") {
					retrieveQuotations()
				}
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	function uploadQuotation(id, customerName) {
		history.push({
			pathname: "upload/" + id + "/" + customerName,
			state: {
				id: id,
				customerName: customerName
			}
		})
	}

	var theme = useTheme()

	const [isSmallScreen, setIsSmallScreen] = useState(false)

	function retrieveQuotations() {
		axios
			.post("https://api.exhibition.tecnodatasystem.eu/retrieveQuotations.php", {
				token: localStorage.getItem("exhibitionToken")
			})
			.then(function (response) {
				var response = response.data
				console.log(response)
				if (response.status == "ok") {
					var x = []
					response.data.forEach(function (resp) {
						var data = JSON.parse(resp.data)
						x.push({
							paid_datetime:
								resp.paid_datetime == null
									? ""
									: resp.paid_datetime.split(" ")[0].split("-").reverse().join("/") +
									  " " +
									  resp.paid_datetime
											.split(" ")[1]
											.substring(0, resp.paid_datetime.split(" ")[1].length - 3),
							amount: resp.amount.replace(".", ","),
							paid: resp.paid,
							id: resp.id,
							email: resp.email,
							code: resp.code,
							is_published: resp.is_published,
							company: data.find((x) => x.name == "Cliente").data.ragioneSociale,
							requested_datetime:
								resp.requested_datetime.split(" ")[0].split("-").reverse().join("/") +
								" " +
								resp.requested_datetime
									.split(" ")[1]
									.substring(0, resp.requested_datetime.split(" ")[1].length - 3),
							status_old_definition_of_status:
								resp.status == "inserted"
									? "Inserito"
									: resp.status == "managed"
									? "Preso in carico"
									: resp.status == "sent"
									? "Inviato al cliente"
									: "Sconosciuto",
							status: resp.is_published == 1 ? "Pubblicato" : "Non pubblicato",

							completed_datetime:
								resp.completed_datetime == null
									? "Non disponibile"
									: resp.completed_datetime.split(" ")[0].split("-").reverse().join("/") +
									  " " +
									  resp.completed_datetime
											.split(" ")[1]
											.substring(0, resp.completed_datetime.split(" ")[1].length - 3)
						})
					})
					setQuotations(x)
				}
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	useEffect(() => {
		var windowWidth = window.innerWidth
		var breakpointWidth = theme.breakpoints.values.md
		setIsSmallScreen(windowWidth < breakpointWidth)

		retrieveQuotations()

		setInterval(() => {
			retrieveQuotations()
		}, 120000);
		
	}, [])

	const columns = [
		{
			name: "company",
			label: "Cliente",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "requested_datetime",
			label: "Data richiesta",
			options: {
				filter: true,
				sort: false
			}
		},
		/*{
			name: "completed_datetime",
			label: "Data completamento",
			options: {
				filter: true,
				sort: false
			}
		},*/
		{
			name: "amount_requested",
			label: "Importo richiesto",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div>
							<Button
								disabled={quotations[tableMeta.rowIndex].paid ? true : false}
								color="primary"
								variant="contained"
								title="Imposta"
								//style={{ backgroundColor: "green" }}
								onClick={() => {
									// var id = quotations[tableMeta.rowIndex].id
									// var customerName = quotations[tableMeta.rowIndex].company
									// viewQuote(id,customerName)
									setCurrentViewAmount(quotations[tableMeta.rowIndex].amount.toString().replace(",","."))
									setCurrentQuotationIdAmountToEdit(quotations[tableMeta.rowIndex].id)
									setIsModalOpen(true)
								}}
							>
								<EuroIcon />
								&nbsp;
								<span style={{ fontWeight: "bold" }}>{quotations[tableMeta.rowIndex].amount}</span>
							</Button>
						</div>
					)
				}
			}
		},
		{
			name: "paid_datetime",
			label: "Data pagamento",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div>
							{quotations[tableMeta.rowIndex].paid_datetime.length > 0
								? quotations[tableMeta.rowIndex].paid_datetime
								: "Non disponibile"}
						</div>
					)
				}
			}
		},
		{
			name: "status",
			label: "Stato",
			options: {
				filter: true,
				sort: false
			}
		},
		{
			name: "actions",
			label: "Azioni",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div>
							<Button
								color="primary"
								variant="contained"
								title="Vedi"
								//style={{ backgroundColor: "green" }}
								onClick={() => {
									var id = quotations[tableMeta.rowIndex].id
									var customerName = quotations[tableMeta.rowIndex].company
									viewQuote(id, customerName)
								}}
							>
								<VisibilityIcon />
							</Button>
							<Button
								color="secondary"
								variant="contained"
								title={
									quotations[tableMeta.rowIndex].is_published
										? "Nascondi Online"
										: "Pubblica Online"
								}
								style={{
									backgroundColor: quotations[tableMeta.rowIndex].is_published ? "green" : "red",
									marginLeft: !isSmallScreen ? 5 : null,
									marginRight: !isSmallScreen ? 5 : null,
									marginBottom: isSmallScreen ? 5 : null,
									marginTop: isSmallScreen ? 5 : null
								}}
								onClick={() => {
									var id = quotations[tableMeta.rowIndex].id
									changePublishState(id, quotations[tableMeta.rowIndex].is_published)
								}}
							>
								<Globe />
							</Button>
							<Button
								color="secondary"
								variant="contained"
								title="Carica Progetto 360°"
								style={{
									backgroundColor: "#0033cc",
									marginLeft: !isSmallScreen ? 5 : null,
									marginRight: !isSmallScreen ? 5 : null,
									marginBottom: isSmallScreen ? 5 : null,
									marginTop: isSmallScreen ? 5 : null
								}}
								onClick={() => {
									var id = quotations[tableMeta.rowIndex].id
									var customerName = quotations[tableMeta.rowIndex].company
									uploadQuotation(id, customerName)
								}}
							>
								<UploadCloud />
							</Button>
							<Button
								color="success"
								variant="contained"
								title="Invia al cliente"
								style={{ backgroundColor: "black", color: "white" }}
								onClick={() => {
									var id = quotations[tableMeta.rowIndex].id
									var email = quotations[tableMeta.rowIndex].email
									var code = quotations[tableMeta.rowIndex].code

									sendQuote(email, code)
								}}
							>
								<SendIcon />
							</Button>
						</div>
					)
				}
			}
		}
	]

	return (
		<>
			<PageTitle title="Preventivi" />
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<MUIDataTable
						//title="Employee List"
						data={quotations}
						columns={columns}
						options={{
							filterType: "checkbox",
							selectableRows: false,
							textLabels: {
								body: {
									noMatch: "Nessun risultato trovato",
									toolTip: "Ordina",
									columnHeaderTooltip: (column) => `Ordina per ${column.label}`
								},
								pagination: {
									next: "Prossima pagina",
									previous: "Pagina precedente",
									rowsPerPage: "Righe per pagina:",
									displayRows: "di"
								},
								toolbar: {
									search: "Cerca",
									downloadCsv: "Scarica CSV",
									print: "Stampa",
									viewColumns: "Vedi Colonne",
									filterTable: "Filtra Tabella"
								},
								filter: {
									all: "Tutti",
									title: "FILTRI",
									reset: "REIMPOSTA"
								},
								viewColumns: {
									title: "Mostra Colonne",
									titleAria: "Mostra/Nascondi Colonne"
								},
								selectedRows: {
									text: "righe selezionate",
									delete: "Elimina",
									deleteAria: "Elimina righe selezionate"
								}
							}
						}}
					/>
				</Grid>
				{/*<Grid item xs={12}>
          <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <Table data={mock.table} />
          </Widget>
        </Grid> */}
				<Modal
					open={isModalOpen}
					onClose={() => {
						setIsModalOpen(false)
						setCurrentViewAmount("0")
						setCurrentQuotationIdAmountToEdit(0)
					}}
					aria-labelledby="Visualizza foto"
					aria-describedby="Visualizza foto"
				>
					<div
						style={{
							textAlign: "center",
							marginTop: "20%",
						}}
					>

						<div style={{justifyContent:'center', display:'flex', flex:1}}>
							<div style={{backgroundColor:'white', paddingBottom:30, paddingTop:30, borderRadius:20, width:'30%'}}>
							<div style={{marginBottom:20}}>
							<span style={{ color: "black", fontSize:30,  }}><X onClick={() => {
								setIsModalOpen(false)
								setCurrentViewAmount("0")
								setCurrentQuotationIdAmountToEdit(0)
							}} /><br /></span>

								<span style={{ color: "black", fontSize:30,  }}>Imposta un importo per il preventivo</span>
							</div>

							<span style={{ fontSize: 20, color: "black" }}>€&nbsp;&nbsp;</span>
							<input
								type="number"
								value={currentViewAmount}
								min="0"
								style={{
									minHeight: 40,
									borderRadius: 20,
									borderWidth: 0.3,
									fontSize: 20,
									paddingLeft: 10
								}}
								onChange={(v) => {
									setCurrentViewAmount(v.target.value)
									//forceUpdate()
								}}
								/*onWheel={() => {
							alert("wheel")
						}}*/
							/>
							<br />
							<Button
								color="primary"
								variant="contained"
								title="Imposta"
								onClick={() => {
									var newAmount = currentViewAmount.replace(",",".")
									actuallySetAmountFunction(currentQuotationIdAmountToEdit, newAmount)
								}}
								style={{ marginTop: 15 }}
							>
								Imposta
							</Button>
							
						</div>
						</div>
					</div>
				</Modal>
			</Grid>
		</>
	)
}
