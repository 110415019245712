import React from "react"
import { HashRouter, Route, Switch, Redirect } from "react-router-dom"

// components
import Layout from "./Layout"

// pages
import Error from "../pages/error"
import Error2 from "../pages/error/Error2"

import Login from "../pages/login"

// context
//import { useUserState } from "../context/UserContext";

export default function App() {
	// global
  //var { isAuthenticated } = useUserState();
  
  function LogoutFunction() {
    localStorage.removeItem("exhibitionToken")
    localStorage.removeItem("exhibitionFullName")
    window.location.reload()

    return(
      <div>asd</div>
    )

  }

	document.title = "Exhibition Lab Design"

	return (
		<HashRouter>
			<Switch>
				<Route exact path="/" render={() => <Redirect to="/app/tables" />} />
				<Route exact path="/app" render={() => <Redirect to="/app/tables" />} />
				<Route path="/app" component={Layout} />
				<Route path="/login" component={Login} />
           
			 <Route component={Error} /> 
		
			</Switch>
		</HashRouter>
	)

	// #######################################################################

	/*function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  } */
}
