import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import View from "../../pages/view/View";
import Upload from "../../pages/upload/Upload"

// context
import { useLayoutState } from "../../context/LayoutContext";

import axios from 'axios'

import { useHistory } from 'react-router-dom'

import CheckToken from '../CheckToken'

function LogoutComponent() {

  localStorage.removeItem("exhibitionToken")
  localStorage.removeItem("exhibitionFullName")
  window.location.reload()

  return (<div>Attendi...</div>)
}

function Layout(props) {
  var classes = useStyles();

  const history = useHistory()

  CheckToken()

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
         {/*   <Route path="/app/dashboard" component={Dashboard}  /> */}    
               <Route path="/app/dashboard"  render={() => <Redirect to="/app/tables" />} />  
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/view/:viewId?/:customerName?" component={View} />

              <Route path="/app/upload/:viewId?/:customerName?" component={Upload} />
              <Route path="/app/notifications" component={Notifications} />
              <Route path="/app/logout" component={LogoutComponent} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>

        
                <Link
                  color={'primary'}
                  href={'https://tds.srl'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Creato da Tecno Data System S.r.l.
                </Link>
              </div>
            {/*  <div>
                <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://github.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={GithubIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div>  */} 
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
